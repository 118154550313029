<template>
  <div id="app">
    <router-view></router-view>
    <template>
      <el-backtop ></el-backtop>
    </template>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
</style>