<template>
  <div>
    <el-row>
      <el-col :span="24">
    <div class="lbt">
      <el-carousel height="800px" :interval="3000" v-show="true">
        <el-carousel-item v-for="(item, index) in imageUrl" :key="index">
          <img :src="item"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    </el-col>
    </el-row>


    <div class="xw"></div>
    
    <div v-for="(item, index) in data" :key="index">
      <el-card  style="margin:10px 10px">
        <h1  style="font-size:40px">{{ item.title }}</h1>
        <div class="bigdiv" style="text-align:center">
          <div class="leftdiv">
            <img :src="require('@/assets/images/'+item.imgUrl)"  class="mainimg dtdh">
          </div>
          <div class="rightdiv">
            <div class="right1 dtdh"  v-for="(text, idx) in item.textContents" :key="idx">
              {{ text }}
            </div>
          </div>
        </div>
      </el-card>
    </div>

  </div>
</template>

<script>
import mainData from '@/api/mainData.json'
export default {
    data() {
      return {
        imageUrl:[
        "/img_lb/lb1.jpg",
        "/img_lb/lb2.jpg",
        "/img_lb/lb3.jpg",
        "/img_lb/lb4.jpg",
        "/img_lb/lb5.jpg",
        "/img_lb/lb6.jpg",
        ],
        data:mainData.main
      };
    },
}
</script>

<style>
.lbt{
  width: 100%;
  position: absolute;
  z-index: 1;
}
.lbt img{
  width: 100%;
}
.xw{
  margin-top: 810px;
  width: 100%;
}
.xw-card{
  margin:10px 10px;
}
h1{
  text-align: center;
}
.bigdiv{
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
}
.leftdiv{
  /* background-color: aqua; */
  width: 48%;
  padding-bottom: 20px;
}
.rightdiv{
  /* background-color:blueviolet; */
  width: 48%;
  height: 440px;
  margin-left: 30px;
}
.right1{
  flex: 1;
  width: 700px;
  height: 80pX;
  /* background-color: antiquewhite; */
  /* line-height: 80px; */
  /* 垂直排列 */
  display: flex;
  flex-direction: column;
  /* 垂直居中 */
  justify-content: center;

  /* margin: 30px 35px; */
  margin-left: 35px;
  border-bottom: solid 1px;
}
.mainimg{
  height: 440.23px;
  margin-left:10px;
}
.right1{
  font-size: 20px;
  text-align: left;
}
/* a{
    text-decoration: none;
    font-size: 20px;
    color:#224179;
  } */
</style>