import Vue from 'vue'
import Router from 'vue-router'
import MainIndex from '../views/MainIndex'
import SlideShow from '../components/SlideShow'
import SchoolCulture from '../views/SchoolCulture'
import SchoolNews from '../views/SchoolNews'
import FacultySummary from '../views/FacultySummary'
import TeacherIntroduce from '../views/TeacherIntroduce'
import searchRes from '../views/searchRes'
import LineChart from '../views/LineChart'
import Zhaosheng from '../views/Zhaosheng'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path:'/',
      redirect:{
      path:'/MainIndex'
      }
    },
    {
      path:'/MainIndex',
      name:'MainIndex',
      component:MainIndex,
      children:[
        {
          path:'',
          name:'SlideShow',
          component:SlideShow
        },
        {
          path:'/schoolculture',
          name:'SchoolCulture',
          component:SchoolCulture
        },
        {
          path:'/schoolnews',
          name:'SchoolNews',
          component:SchoolNews
        },
        {
          path:'/FacultySummary',
          name:'FacultySummary',
          component:FacultySummary
        },
        {
          path:'/TeacherIntroduce',
          name:'TeacherIntroduce',
          component:TeacherIntroduce
        },
        {
          path:'/searchRes',
          name:'searchRes',
          component:searchRes
        },
        {
          path:'/LineChart',
          name:'LineChart',
          component:LineChart
        },
        {
          path:'/Zhaosheng',
          name:'Zhaosheng',
          component:Zhaosheng
        },
      ]
    },
    
  ]
})