<template>
  <div>
     <img src="../assets/list-banner.png" style="width:100%">
      
    <!-- 面包屑导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/MainIndex' }"> 首页 </el-breadcrumb-item>
      <el-breadcrumb-item>校园风采</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="fistcard" >
      <div>
    <ul class="tp">
        <el-card class="card">
          <h1>校园风景</h1>
            <div class="bk">
             <div class="zp">
            <img src="../assets/images/1.jpg" class="dtdh">
             </div>
            <div class="zw">
              <p>学校是成都市重点打造的一所工科职业院校，肇始于1951年，2014年经四川省人民政府批准设立为公办全日制高等职业技术学院。</p><p>2021年，学校入选四川省“双高计划”建设单位。现有天府校区、东校区（金堂）、财贸校区、新都校区、公交校区5个校区，占地面积近1000亩，教学科研设备价值超亿元，图书馆藏书量70余万册，在校全日制高职学生16000余人。重点打造智能制造、交通运输、信息技术、建筑工程和财经商贸5大专业集群，设置工业机器人、城市轨道交通运营管理、信息安全与管理、新能源汽车等37个专业。</p>
             <p>学校坚持国际化办学方向，与AHK德国工商大会、IMI英国汽车工业学会、WIFI奥地利联邦商会经济促进学院深入合作，引入国际优质教育教学资源，授权培训与颁发汽车与智能制造等国际先进职业资格认证体系及职业资格证书近10项。响应“一带一路”倡议，在老挝、泰国等国家建立轨道交通、智能制造与汽车、财经商贸等“海外分院”，输出先进专业标准与国际双语课程，促进中华文化广泛传播。</p>
            </div>
            <div class="db">
              <p><b>校训：</b>以德润身 技臻至善</p>
            “校风、教风、学风”。校风、教风、学风是学院精神、价值观和办学理念的集中体现，是学院文化建设的核心内容。<br>我院“三风”紧紧围绕学院发展理念、发展定位、发展目标及发展战略，旨在建设积极向上的学院文化，塑造求实进取的学院精神。<br>
             <p><b>校风：</b>勿以恶小而为之勿以善小而不为</p>
             <p><b>教风：</b>学为人师行为世范</p>
             <p><b>学风：</b>锲而不舍精益求精</p>
            </div>
            </div>
            </el-card>
      
       
         <el-card class="card">
          <h1>校园美食</h1>
            <div class="bk">
             <div class="zp">
             <img src="../assets/images/2.jpg" class="dtdh">
             </div>
            <div class="sg">
               <b><p>炸炒炖烤煮，酸甜苦辣咸</p>
               <p>世间唯有爱与美食不可辜负</p>
               <p>一间食堂盛人间烟火，一种食物藏匠心温情</p>
               <p>校园美食的百般滋味在味蕾中碰撞</p>
               <p>便有了属于每位学子的独家校园记忆</p>
               <p>才下舌尖，又上心间</p>
               <p>让我们共赴一场与美食的约会</p></b>
            </div>
            </div>
            </el-card>

        
        <el-card  class="card">
          <h1>校外风景</h1>
            <div class="bk">
             <div class="zp">
             <img src="../assets/images/3.jpg" class="dtdh">
              <img src="../assets/images/11.jpg" class="dtdh">
             </div>
            <div class="zw">
              <p>淮口镇地处成都市龙泉山脉东部，金堂县域中部地区，西距成都市区48公里，北距金堂县城23公里。为成都市东进主战场之一的淮州新城核心区，镇域内有沱江穿山越谷，于淮口城区划出“U”形大弯，形成水面宽阔、地势平坦的天然良港和宜居之地。全镇幅员面积109平方公里，辖8个村18个社区，总人口11万人，2016年地区生产总值达118.27亿元，先后被评定为“全国重点镇”、“四川省‘百镇建设行动’试点镇”、“成都市小城市建设示范镇”，2016年被确定为成都市卫星城。2017年确定为成都东进三大新城之一的淮州新城</p><br><br>
              <p><b>地形</b></p>
                 <p>淮口镇镇域地貌东南部为河流冲积平原和阶地浅丘，浅丘起伏不大，一般高差为20—40米；西北部为深丘山区，高差较大。海拔422.5—971.5米。</p>
              <p><b>气候</b></p> 
                 <p>镇属亚热季风气候，气候温和，四季分明，雨量充沛，湿度大，云雾多，乏日照，平均风速小，无霜期长，大陆性季风气候显著，年均气温为16.6℃，年平均降雨量为920.5毫米。土壤质地良好，十分有利于多种农作物的生长和农业的各种经营生产。</p>
              <p><b>水文</b></p>
                 <p>沱江流经镇域28公里，由北向南穿越全镇，水资源丰富</p>
              <p><b>自然资源</b></p>
                 <p>境内河岸滩有丰富的砂石资源，盛产黄辣丁、三角锋等10余个种类的鱼。</p>
            </div>
            </div>
            </el-card>
           
           
       
         <el-card  class="card">
          <h1>校外景物</h1>
            <div class="bk">
             <div class="zp">
             <img src="../assets/images/4.jpg" class="dtdh">
             </div>
               <div class="zw">
              <p>淮口镇是一座“看得见山，望得见水，记得住乡愁”的地方。 这里风景优美，文脉厚重，还有许多迷人的传说。 特别是东岸，依山傍水，屋舍俨然，很多优美的故事就如同这里的房屋，层层叠叠，从江边延伸到山顶，又从山顶随风飘向远方。</p><br>
           <p><b><i>“白烟弥漫接天涯，黯然长空一道斜。</i><br><br>
               <i>有似钱塘江上望，晚潮初落见平沙。”</i><br><br></b>
               这是唐宋八大家之一的北宋著名诗人王安石描写的《望淮口》七言诗。</p>
            </div>
            </div>
            </el-card>
    </ul> 
  </div>
  </el-card>
  </div>
</template>

<script>
export default {
  name: "SchoolCulture",
  data() {
    return {
  }
},
    }
</script>

<style scoped>
.tp{
  padding: 0px;
}
.tp img{
  width: 600px;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bk{
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.zw{
  width: 900px;
  margin-right: 20px;
  line-height: 50px;
  font-size:25px;
}
.zp{
  width: 500px;
}
p{
  text-indent:2em;
}
.db{
  margin: 20px 20px;
  font-size:25px
}
.info-card {
  margin-top: 20px;
  width: 100%;
}
.sg{
  width: 750px;
  margin-right: 20px;
  line-height: 55px;
  color: #252424;
  font-size:25px
}
h1{
  text-align:center;
  font-size: 35px;
}
.el-card__body{
  margin-bottom: 50px !important;
  padding-top: 50px !important;
}
.card{
    margin-bottom: 50px !important;
}
.fistcard{
  margin-left: 20px;
  margin-right: 20px;
}
</style>