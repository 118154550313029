<template>
  <div>
    <img src="../assets/list-banner.png" style="width:100%">
      
    <!-- 面包屑导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/MainIndex' }"> 首页 </el-breadcrumb-item>
      <el-breadcrumb-item>校园新闻</el-breadcrumb-item>
    </el-breadcrumb>
     <div>
    <!-- 内置的卡片组件 -->
    <el-card style="margin:20px 20px">  
    <!-- 绑定对象 -->
    <div class="content">
      <div>
        <div class="top">
          <!-- 图像嵌入 -->
          <img src="../assets/images/5.jpg" alt="图片加载失败！" />
        </div>
        <div class="bottom">
        <a href="https://www.cdivtc.com/info/1013/8230.htm"  target="_blank">党委副书记、院长苏延川带队开展“五一”节前校园安全检查</a>
        </div>
      </div>
      
      <div>
        <div class="top">
          <img src="../assets/images/6.jpg" alt="图片加载失败！" />
        </div>
        <div class="bottom">
           <a href="https://www.cdivtc.com/info/1013/8208.htm"  target="_blank">学校组织开展保密知识专题培训</a>
        </div>
      </div>
      <div>
        <div class="top">
          <img src="../assets/images/7.png" alt="图片加载失败！" />
        </div>
        <div class="bottom">
           <a href="https://www.cdivtc.com/info/1013/8206.htm"  target="_blank">学校召开2023年网络安全工作会议</a>
        </div>
      </div>
      <div>
        <div class="top">
          <img src="../assets/images/8.jpg" alt="图片加载失败！" />
        </div>
        <div class="bottom">
          <a href="https://www.cdivtc.com/info/1013/8150.htm"  target="_blank">我校教职工羽毛球队在市教科文卫体工会职工羽毛球比赛中斩获佳绩</a>
        </div>
      </div>
      <div>
        <div class="top">
          <img src="../assets/images/9.jpg" alt="图片加载失败！" />
        </div>
        <div class="bottom">
           <a href="https://www.cdivtc.com/info/1013/8186.htm"  target="_blank">成都工业职业技术学院领导干部学习贯彻习近平新时代中国特色社会主义思想主题教育读书班举行</a>
        </div>
      </div>
      <div>
        <div class="top">
          <img src="../assets/images/10.jpg" alt="图片加载失败！" />
        </div>
        <div class="bottom">
         <a href="https://www.cdivtc.com/info/1013/8152.htm"  target="_blank">奔跑吧！工职青年！我校举办首届川剧元素校园定向越野赛</a>
        </div>
      </div>
    </div>
    </el-card>
  </div>
  </div>
  
</template>


<script>
export default {
  name: "SchoolNews",
  data() {
    return {      
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  font-size: 20px;
  color:#224179;
}
.content {
  margin-top:10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.top img{
  width: 600px;
}
.content > div {
  width: 520px;
  height: 350px;
  margin-bottom: 20px;
  border: 2px solid rgba(13, 130, 240, 0.5);
  cursor: pointer;
}
.top {
  height: 250px;
  overflow: hidden;
}
.top > img {
  width: 100%;
  transition: transform 1s;
}
.top > img:hover {
  transform: scale(1.2);
}
.bottom {
  height: 50px;
  padding: 25px;
  line-height: 40px;
  background-color: rgba(70, 192, 245, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  /* 垂直居中 */
  justify-content: center;
}
.bottom:hover {
  font-weight: bold;
  color: #2e13c4;
}
/* .info-card {
  margin-top: 20px;
  width: 100%;
} */
.bread{
  margin: 20px 50px !important;
  font-size: 15px;
}
</style>