<template>
  <div>
     <img src="../assets/list-banner.png" style="width:100%">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/MainIndex' }">
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>师资概况</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="height:1080px">
      <p style="font-size: 30px">
        <center><h1>名师介绍</h1></center>
      </p>
      
      <div class="container">
        <img src="@/assets/images/wg.jpg" />
        <center><h1>文广</h1></center>
        <div class="bottom">
          <p>
            <b>
              教授，吉林大学博士。先后主持科技部重点基础材料技术提升与产业化专项下子课题1项，四川省应用基础项目1项，
              人工智能四川省重点实验室重点项目1项，教育厅教育教改项目2项，攀枝花市科研项目4项；
              主研省科技厅及教育厅项目7项，攀枝花市科研项目3项。获得四川省教育教学成果二等奖1项，攀枝花市科技进步二等奖2项。
              在《中国机械工程》、《吉林大学学报（工学版）》及“WCICA全球智能控制大会”等国际会议上发表学术论文30余篇
            </b>
          </p>
        </div>
      </div>

      <div class="container">
        <img src="@/assets/images/xjy.jpg" />

        <br />
        <center><h1>熊建云</h1></center>
        <br />
        <div class="bottom">
          <p>
            <b>
              教授，信息工程学院副院长，先后获得四川省高校“十佳青年教师”提名奖，
              四川省高等教育教学成果一等奖、三等奖各1项，四川省职业教育教学成果一等奖1项，主持省级教改项目3项，主编国家规划教材1本。
            </b>
          </p>
        </div>
      </div>

      <div class="container">
        <img src="@/assets/images/w.jpg" />

        <br />
        <center><h1>武昭妤</h1></center>
        <div class="bottom">
          <p>
            <b>
              教授，博士，智能焊接技术专业带头人，长期从事金属材料制备及其焊接性研究。
              先后荣获学校首届“十佳优秀教师”、“最受学生欢迎教师”称号，2021年评为成都市学科带头人。
            </b>
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "TeacherIntroduce",
};
</script>

<style scoped>
.container img {
  width: 300px;
  height: 200px;
  transition: transform 0.3s ease-in-out;
  /* 鼠标悬停在图片上，图片会以0.3秒时间缓慢放大 */
}
.container img:hover {
  transform: scale(1.2);
  /* 鼠标悬停在图片上，图片会以缓慢动画效果放大1.2倍 */
}

.top {
  height: 200px;
  overflow: hidden;
  /* 隐藏溢出 */
}

.bottom:hover {
  font-weight: bold;
  /* 字体加粗 */
  color: rgb(83, 209, 231);
}
.container img {
  float: left;
  /* 左浮动 */
  width: 300px;
  height: 200px;
  margin-right: 150px;
  /* 右外边距 */
  margin-left: 100px;
  /* 左外边距 */
}
.container .text {
  float: right;
  /* 右浮动 */
  width: 300px;
  height: 200px;
}
p {
  /* 文本首行缩进2字符 */
  text-indent: 2em;
}

el-card{
  width: 80%;
}
.container{
  height: 250px;
}
</style>