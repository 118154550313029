<template>
  <div>
    <img src="../assets/list-banner.png" style="width:100%">

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/MainIndex' }">
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>就业信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div ref="charts" class="chart-div"></div>
    </el-card>
  </div>
</template>

<script>
export default {
    name:"LineChart",
    data(){
        return {
            option:{
                title: {text: "2017年-2022年成都工业职业技术学院毕业生就业率数据"},
                tooltip:{},
                xAxis: {
                    type: "category",
                    data: [
                        "2017年",
                        "2018年",
                        "2019年",
                        "2020年",
                        "2021年",
                        "2022年",
                    ],
                },
                yAxis: {
                    type: "value",
                    axisLabel: { //y轴设置为%
                    show: true,
                    interval: 'auto',
                    formatter: '{value} %',
                },
                max: 100,  //最大值
                min: 0,  //最小值
                },
                series: [
                    {
                        data: [
                        68, 73, 79, 82, 80, 83
                        ],
                        type: "line",
                        name:"就业率"
                    },
                ],
            },
        };
    },
    methods: {
        initCharts() {
            let myChart = this.$echarts.init(this.$refs.charts);
            // 绘制图表
            myChart.setOption(this.option);

        },
    },
    mounted() {
        this.initCharts();
    },
}
</script>

<style scoped>
.chart-div {
  width: 600px;
  height: 400px;
  margin-left: 30%;
  border: solid black 1px;
}

.box-card {
  margin: 10px 80px;
}
</style>