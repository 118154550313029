<template>
  <div style="margin-top:200px">

    <!-- 测试 -->

    <!-- <img src="../assets/images/zdr.jpg" alt=""> -->



      <el-card v-for="(item,index) in $route.query.res" :key="index" class="big_card dtdh">
        <a :href="item.url" target="_blank">

        <el-row>
          <el-col :span="6"><div class="ResImg">
              <img :src="require('@/assets/images/'+item.img)" />
              </div>
            </el-col>
          <el-col :span="18"><div class="ResContent">
            <p>{{item.content}}</p>
          </div>
          
          </el-col>
        </el-row>
        </a>
      </el-card>
      <div style="heiht:100px,width:1px"></div>


  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.big_card{
  margin: 10px 80px;
}
.ResImg{
  height: 168.73px;
}
.ResImg img{
  margin-left: 30px;
  height: 168.73px;
  max-width: 340px;
}
.ResContent{
  height: 168.73px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}
p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
a {
    text-decoration: none;
    font-size: 20px;
    color:#224179;
  }
</style>