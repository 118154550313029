<template>
    <div>
    <img src="../assets/list-banner.png" style="width:100%">

        <!-- 面包屑导航 -->
        <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/MainInfo' }">
                首页
            </el-breadcrumb-item>
            <el-breadcrumb-item>招生就业</el-breadcrumb-item>
        </el-breadcrumb>
        <video controls >
     <source src="../assets/video/schoolvideo.mp4" type="video/mp4">
     </video>
        <el-card class="info-card">
    <div class="campus-recruitment">
    <h1>{{ title }}</h1>
    <p>{{ description }}</p>

    <h1>招生目标</h1>
    <ul>
      <li v-for="goal in goals" :key="goal" class="mb">{{ goal }}</li>
    </ul>

    <h1>招生专业</h1>
    <ul>
      <li v-for="major in majors" :key="major" class="mb">{{ major }}</li>
    </ul>

    <h1>招生条件</h1>
    <ul>
      <li v-for="condition in conditions" :key="condition" class="mb">{{ condition }}</li>
    </ul>

    <h1>申请流程</h1>
    <ol>
      <li v-for="step in steps" :key="step" class="mb">{{ step }}</li>
    </ol>

    <h1>联系信息</h1>
    <p>地址：{{ contact.address }}</p>
    <p>电话：{{ contact.phone }}</p>
    <p>电子邮件：{{ contact.email }}</p>
    </div>

        </el-card>
    </div>
</template>

<script>
export default {
  data() {
    return {
      title: '校园网招生简介',
      description: '欢迎加入我们成都工业职业技术学院信息工程学院！',
      goals: [
        '培养具备网络技术与计算机知识的专业人才',
        '提供优质的网络技术教育和实践机会',
        '推动校园网络发展和应用创新'
      ],
      majors: ['移动应用开发', '物联网应用技术', '现代移动通信技术','云计算技术应用','大数据技术','软件技术','工业互联网技术',],
      conditions: [
        '具备高中毕业（或同等学历）证书',
        '对计算机科学和网络技术有兴趣',
        '具备良好的团队合作和沟通能力'
      ],
      steps: [
        '填写在线申请表格',
        '参加面试',
        '提交相关材料',
        '等待录取通知'
      ],
      contact: {
        address: '成都市天府新区正兴镇大安路818号',
        phone: '028-64458874、64458898、64458852',
        email: 'zjc@cdivtc.com'
      }
    };
  }
};
</script>

<style scoped>
.edit-Form {
  width: 40%;
  height: 15%;
  margin-left: 25%;
}
.info-card {
  margin: 10px 80px;
}
.campus-recruitment {
  font-family: Arial, sans-serif;
  color: #333;
}
h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
ul, ol {
  margin-left: 20px;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 585px;
}
p {
  margin-bottom: 6px;
  text-align: center;
}

video {
  width: 100%;
  display: block;
  top: 50%;
  left: 50%;
}
.mb{
  margin-left: 520px;
}
</style>