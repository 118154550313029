<template>
  <div class="nav">

    <el-container style="min-height: 756px;">
      <top-logo></top-logo>
      <el-main>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
      <el-footer style="margin-top:50px">
        <Footer></Footer>
      </el-footer>
      
    </el-container>
  </div>
</template>




<script>
import TopLogo from '../components/TopLogo.vue'
import Footer from '../components/Footer.vue'
  export default { 
    name:"MainIndex",
    components:{
        TopLogo,Footer
    },
    data(){
      return{
        dislbt:true
      }      
    },
    methods: {
    },
  }
</script>

<style scoped>
.mainmenu{
  background-color: transparent !important;
}
/* .el-submenu:hover{
    outline: 0 !important;
    color: #409EFF !important;
    background: transparent !important;
} */

/* 去掉element布局自带的内边距 */
.el-container,.el-main,.el-footer{
  padding: 0;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }

.fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>

