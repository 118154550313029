<template>
  <div>
    <!-- 网站顶部背景 -->
    <div class="topBj">

    <!-- 网站logo，点击可以回到网站首页 -->
    <div class="logo">
      <router-link to="/MainIndex">
        <img src="../assets/logo.png" alt="图片加载失败！"/>
      </router-link>
    </div>

    <!-- <div v-for="(item,index) in Arr" :key="index" class="res">
      <h1>{{item.title}}</h1>
    </div> -->

    <!-- 搜索框，点击后显示输入框，点击其他位置输入框隐藏 -->
    <div class="search">
      <transition name="my-transition">
        <el-input placeholder="请输入内容" size="mini" v-model="searchVal" ref="myButton"
          v-bind:class="{ 'my-input': true }" v-bind:style="{ transform: buttonStyle.transform, 'box-shadow': buttonStyle['box-shadow'],
          'width': buttonStyle['.el-input__inner'],'background-color':buttonStyle['background-color'], 'background-filter':buttonStyle['filter']}"
          v-on:focus="onButtonFocus()" v-on:blur="onButtonBlur()" @keyup.enter.native="search()"> 
          <i slot="prefix" class="el-input__icon el-icon-search" style="color:#224179" @click="search()"></i>
        </el-input>
      </transition>
    </div>

     <!-- 导航栏，包括首页、师资队伍、校园文化、招生就业等菜单 -->
    <div class="dhl">
      <el-menu mode="horizontal" background-color="transparent" text-color="#fff" active-text-color="#ffffff" router>
        <el-menu-item index="/MainIndex" style="font-size:17px">首页</el-menu-item>
        <el-submenu index="1">
          <template slot="title" >师资队伍</template>
          <el-menu-item index="/FacultySummary" class="menu_bj">师资概况</el-menu-item>
          <el-menu-item index="/TeacherIntroduce" class="menu_bj">名师介绍</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">校园文化</template>
          <el-menu-item index="/schoolculture" class="menu_bj">校园风采</el-menu-item>
          <el-menu-item index="/SchoolNews" class="menu_bj">校园新闻</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title" >招生就业</template>
          <el-menu-item index="/Zhaosheng" class="menu_bj">招生信息</el-menu-item>
          <el-menu-item index="/LineChart" class="menu_bj">就业信息</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>

</div>

  </div>
</template>

<script>
import demoData from '../api/demoData'
export default {
  data() {
    return {
      testData:demoData.data,
      Arr:'',
      buttonStyle: {
        'filter':'blur(0px)',
        'background-color':'transparent !important',
        '.el-input__inner':'110px',
        transform: '',
        'box-shadow': ''
      },
      searchVal: '', // v-model绑定的变量
      // 添加此行，用于控制输入框的显示和隐藏
      showInput: false 
    };
  },

  methods: {
    // 当搜索按钮被聚焦时改变输入框的位置和样式，并将showInput设为true
    onButtonFocus() {
      this.showInput = true;
      this.buttonStyle = {
        'filter':'blur(5px)',
        'background-color':'#fff !important',
        '.el-input__inner':'400px',
        transform: 'translate(-400px,0px) scale(1.5)',
        'box-shadow': '3px 35px 77px -17px rgba(0, 0, 0, 0.8)'
      };
    },
    
    // 当输入框失焦时，将showInput设为false，让输入框消失并恢复搜索按钮的样式
    onButtonBlur() {
      this.showInput = false;
      this.buttonStyle = {
        'filter':'blur(0px)',
        'background-color':'transparent !important',
        '.el-input__inner':'110px',
        transform: '',
        'box-shadow': ''
      };
    },

    search(){
      if(this.searchVal.length==0){
        this.$notify.error({
          title: '搜索框为空',
          message: '请输入要搜索的内容',
          offset: 100
        });
        console.log("输入框无内容");
        return false
      }else{
        this.Arr = demoData.data.filter(item => item.content.includes(this.searchVal));
        if(this.Arr.length==0){
          console.log("没有搜索到结果");
          this.$notify({
          title: '消息',
          message: '没有搜索到相关内容',
          type: 'warning',
          offset: 100
        });
          return false
        }else{
          this.$notify({
          title: '搜索到'+this.Arr.length+'条数据',
          // message: '这是一条成功的提示消息',
          type: 'success',
          offset: 100
        });
        this.$router.replace({ path: "/searchRes",query: {res: this.Arr } });
        console.log(this.Arr);
        }
      }
    }
  }
}
</script>

<style>
.topBj{
  width: 100%;
  height: 130px;
  position: fixed;
  z-index: 3;
  background: linear-gradient(rgba(15, 57, 156, 0.605) 40%, rgba(21, 116, 240, 0))
}

.logo{
  position:fixed;
  left:10px;
  top:15px;
  margin-left: 80px;
  margin-top: 0px;
  z-index: 3;
}

.res{
  position:fixed;
  z-index: 3;
  width: 100px;
  height: 100px;
  background-color:aqua;
}

.search{
  position:fixed;
  z-index: 3;
  /* margin: 10px; */
  top: 35px;
  left: 1070px;
  /* transition: all 0.3s ease-in-out;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: all; */
}
 /* 添加search:focus样式，当搜索框被聚焦时改变位置和样式 */
/* .search:focus{
  transform: translate(-200px,0px) scale(1.2);
  box-shadow: 3px 35px 77px -17px rgba(0, 0, 0, 0.8);
} */
/* .el-input__inner:focus{
  width: 500px  !important;
} */
.el-input{
  border-radius: 4px;
}
.el-input__inner{
  background-color: transparent !important;
  color: #000000 !important;
  /* width: 500px  !important; */
}

.dhl{
  position:fixed;
  top: 15px;
  left: 1200px;
  z-index: 3;
}

/* 修改二级菜单样式 */
.el-submenu__title {
  background-color: transparent !important;
  font-size: 17px !important;
}
.el-menu-item{
  color:#ffffff!important; /* 修改二级标题字体颜色 */
  background-color: transparent !important; /* 修改二级标题背景颜色 */
  font-size: 15px; /* 修改二级标题字体大小 */
}
.el-menu-item.is-active,.el-menu-item:hover,.el-submenu__title.is-active,.el-submenu.is-active,
.el-submenu__title:hover {
  background-color: #224179 !important;
}

 /* 过渡动画样式 */
.my-input {
  transition: all 0.4s ease-in-out;
}

.my-input-enter {
  opacity: 0;
  transform: translateX(100%);
}

.my-input-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.my-input-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.my-input-leave-active {
  opacity: 1;
  transform:translateY(0%);
}
.menu_bj{
  background-color: #2241799a  !important;
}
</style>