import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/CSS/GlobalStyle.css'
import * as echarts from 'echarts'

// import './plugins/element.js'

Vue.config.productionTip = false
Vue.prototype.$echarts=echarts


Vue.use(ElementUI);


new Vue({
  el: '#app',
  router,
  components: { App },
  render: h => h(App)
}).$mount("#app")
