<template>
  <div>
    <div>
      <img src="../assets/banner.jpg" class="tp">
    </div>
    
    <div class="tc"></div>
    <div class="footer_logo"><img src="../assets/logo.png" width="100%"></div>

    <div class="motto">以德润身 技臻至善</div>

    <div class="address">
      <font color="#beceee">地址：四川省成都市天府新区正兴街道大安路818号</font>
    </div>

    <div class="Links">
      <el-collapse>
        <el-collapse-item title="友情链接">
          <el-row>
        <el-col :span="8"  v-for="(item,index) in Link" :key="index">
          <div class="grid-box dtdh"><a :href="item.url">{{item.title}}</a></div></el-col>
      </el-row>
        </el-collapse-item>
      </el-collapse>
    </div>


    <div class="QC_bigdiv">

      <div class="QC_div" v-for="(QC,idx) in QC" :key="idx">
        <div class="QC">
          <img :src="require('@/assets/images/'+QC.QCURL)" class="dtdh">
        </div>
        <div class="QCtitle">
          <font color="#beceee">{{QC.title}}</font>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Data from '../api/demoData'
export default {
  data(){
    return{
      Link:Data.Links,
      QC:Data.QC
    }
  }

}
</script>

<style scoped>
.tp{
  width:100%;
  height: 300px;
  position: absolute;
}
.tc{
  height: 300px;
  width: 100%;
  position: absolute;
  background-color: #224179ce;
}
.motto{
  position: absolute;
  margin: 100px 50px;
  color:#beceee ;
  font-size: 120px;
  font-family: STXingkai;
  transition: all 0.3s ease-in-out;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: all;
}
.motto:hover{
  transform: scale(1.06);
  text-shadow: 0 0 0.5em #0d2e6b, 0 0 0.2em #224179;
}
.address{
  position: absolute;
  margin: 250px 50px;
}
.QC_bigdiv{
  position: absolute;
  width: 376px;
  flex-direction: row;
  display: flex;
  margin: 170px 1100px;
}
.QC_div{
  width: 94px;
  margin-left: 20px;
}
.QC img{
  width: 94px;
  height: 94px;
}
.QCtitle{
  text-align: center;
}
.footer_logo{
  position: absolute;
  margin: 20px 20px;
  opacity: 0.7;
  /* z-index: 3; */
}
.Links{
  position: absolute;
  margin: 20px 1120px;
  width: 430px;
}
a {
  text-decoration: none;
  color: #beceee !important;

}
.grid-box{
  text-align: center;
  height: 50px!important;
    /* 垂直排列 */
  display: flex;
  flex-direction: column;
  /* 垂直居中 */
  justify-content: center;
}
</style>