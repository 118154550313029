<template>
  <div>
    <img src="../assets/list-banner.png" style="width:100%">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/MainIndex' }">
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>师资队伍</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div>
        <p style="font-size: 30px">
          <center><h1>师资概况</h1></center>
        </p>
        <div style="text-align: center">
        <img src="@/assets/images/FS.png" />
        </div>
        <p style="font-size: 20px">
          <center>
            <p><b>
              校内兼职教师占比为46%，校内教师占比为32%，企业专职教师占比22%
              学校现有专任教师471人，高级职称占比39.5%，硕博学位占比58.6%，
              双师型教师占比77.84%。拥有国家级技能大师1人，全国技术能手1人，省市特级教师7人，
              省级高层次领军人才1人，全国高校思想政治理论课教学展示一等奖1人，全省脱贫攻坚先进个人1人，四川省高校辅导员年度人物入围1人。
            </b>
            </p>
          </center>
        </p>
      </div>

    </el-card>
  </div>
</template>

<script>
export default {
  name: "FacultySummary",
  data() {
    return {};
  },
};
</script>

<style>
p {
  /* 文本首行缩进2字符 */
  text-indent: 2em;
}
</style>